const scrollPhotoToView = (id: string) => {
  const photoElement = document.getElementById(id)
  if (!photoElement) return

  setTimeout(() => {
    photoElement.scrollIntoView({
      block: "center",
    })
  }, 0)
}

export { scrollPhotoToView }
