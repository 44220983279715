import { nanoid } from "nanoid"
import { useRef } from "react"

/**
 * Создает уникальный идентификатор и сохраняет его между ререндерами компонентa
 * @returns {string} уникальный идентификатор
 */
export const useId = () => {
  const { current: id } = useRef(nanoid())

  return id
}
